<!--
File: Report_4_RoadLengthByTraffic.vue
Description: produces the report for printing.
-->
<template>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-20">
          <RegionsDropdown class='region-select-dropdown' :label="$t('label.select_region')" v-model="selectedRegion"
            :initial_value="selectedRegion" @input='onRegionChange' />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <DepsDropdown class='region-select-dropdown' :label="$t('label.select_deu')" v-model="selectedDeu"
            :initial_value="selectedDeu" :region_id="selectedRegion" @input='onDeuChange' :disabled='!selectedRegion' />
        </div>
        <!--div class="md-layout-item md-small-size-100 md-size-20">
          <RoadClassesDropdown :label="$t('road_network.road_class')" v-model="selectedRoadClass"
            :initial_value=selectedRoadClass @input='onRoadClassChange' />
        </div-->
        <div class="md-layout-item md-small-size-100 md-size-10">
          <md-field>
            <md-input type="number" class="mb-3" clearable style="width: 100px" :placeholder="$t('label.startYear')"
              v-model="startYear" />
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-10">
          <md-field>
            <md-input type="number" class="mb-3" clearable style="width: 100px" :placeholder="$t('label.endYear')"
              v-model="endYear" />
          </md-field>
        </div>
        <div class="md-layout-item btn-row md-small-size-100">
          <md-button class="md-success" @click="print" :disabled="btnDisabled"> {{ $t('buttons.print') }} </md-button>
        </div>
      </div>
    </md-card-header>

    <md-card-content>
      <md-progress-spinner v-if="showSpinner == true" class="md-progress-spinner" :md-diameter="70"
        md-mode="indeterminate" />
      <md-table class="paginated-table table-striped table-hover" :value="tableData"
        :md-sort.sync="currentSort" :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" md-fixed-header>
        <md-table-empty-state :md-label="$t('label.no_data')" :md-description="$t('messages.select_another_criteria')" />
        <md-table-row slot="md-table-row" slot-scope="{item}">
          <md-table-cell :md-label="$t('budget.year')">
            {{ item.survey_year }}
          </md-table-cell>
          <md-table-cell style="min-width: 200px" v-if="!selectedRegion" :md-label="$t('road_network.region')">
            {{ item.region_description }}
          </md-table-cell>
          <md-table-cell v-if="!selectedDeu" :md-label="$t('road_network.dep')">
            {{ $t('road_network.dep') + '-' + item.deu_description }}
          </md-table-cell>
          <md-table-cell :md-label="$t('label.very_very_high')" md-numeric>
            {{ item.length_km_above_24000 | numFormat(3) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('label.very_high')" md-numeric>
            {{ item.length_km_between_12001_and_24000 | numFormat(3) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('label.high')" md-numeric>
            {{ item.length_km_between_7001_and_12000 | numFormat(3) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('label.medium')" md-numeric>
            {{ item.length_km_between_3001_and_7000 | numFormat(3) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('label.low')" md-numeric>
            {{ item.length_km_between_1001_and_3000 | numFormat(3) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('label.very_low')" md-numeric>
            {{ item.length_km_between_251_and_1000 | numFormat(3) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('label.very_very_low')" md-numeric>
            {{ item.length_km_below_250 | numFormat(3) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.total_km')" md-numeric>
            {{ item.total_km | numFormat(3) }}
          </md-table-cell>
        </md-table-row>
      </md-table>
      <div class="md-layout">
        <!--<div>{{ $t('total_regions: ') }}: {{ total_regions }}&nbsp; </div>
            <div>{{ $t('total_deu: ') }}: {{ total_deu }}&nbsp; </div>-->
      </div>
    </md-card-content>
  </md-card>
</template>
<script>
  import RegionsDropdown from '../Dropdowns/RegionsDropdown.vue'
  import DepsDropdown from '../Dropdowns/DepsDropdown.vue'
  //import RoadClassesDropdown from '../Dropdowns/RoadClassesDropdown.vue'
  import { numFormat } from "@/store/refdata/format_helpers"
  import { customSortMixin } from '@/customSortMixin'

  export default {
    mixins: [customSortMixin],
    name: 'report-road-length-by-traffic',
    data() {
      return {
        selectedRegion: 0,
        selectedDeu: 0,
        startYear: null,
        endYear: new Date().getFullYear(),       // 2024,
        selectedRoadClass: '',
        btnDisabled: true,

        showSpinner: false,
        currentSort: 'region_description',
        currentSortOrder: 'asc',
        regionDescr: null,
        deuDescr: null
      }
    },
    components: {
      RegionsDropdown,
      DepsDropdown,
      //RoadClassesDropdown,
    },

    created() {
      this.startYear = new Date().getFullYear() - 5
    },

    methods: {
      reloadData() {
        this.showSpinner = true
        this.btnDisabled = true
        const report_filters = {
          start_year: this.startYear,
          end_year: this.endYear,
          region_id: this.selectedRegion,
          road_class: this.selectedRoadClass,
          deu_id: this.selectedDeu
        }
        this.$store.dispatch('REPORT_4_LENGTH_BY_TRAFFIC', report_filters).then(() => {
          this.btnDisabled = false
          this.showSpinner = false
        })

      },
      print() {
        this.showSpinner = true
        let prtHtml = "<h4 style='text-align:center'>" + this.$t('label.reports_title') + "</h4>"
        prtHtml += "<h4 style='text-align:center'>" + this.$t('label.report4_title')
        prtHtml += '&nbsp;' + this.startYear + ' - ' + this.endYear + '<br>'

        let titleFilters = ''
        let tableHeaders = `<tr><th>${this.$t('budget.year')}</th>`
        if (this.selectedRegion) {
          titleFilters += this.$t('label.from_region', { name: this.regionDescr })
        } else {
          tableHeaders += `<th>${this.$t('road_network.region')}</th>`
        }
        if (this.selectedDeu) {
          titleFilters += ', &nbsp;' + this.$t('road_network.dep') + ': ' + this.deuDescr
        } else {
          tableHeaders += `<th>${this.$t('road_network.dep')}</th>`
        }
        if (titleFilters !== '') prtHtml += '&nbsp;(' + titleFilters + ')'
        prtHtml += '</h4>'

        tableHeaders += `<th>${this.$t('label.very_very_high')}</th>
          <th>${this.$t('label.high')}</th>
          <th>${this.$t('label.medium')}</th>
          <th>${this.$t('label.low')}</th>
          <th>${this.$t('label.very_low')}</th>
          <th>${this.$t('label.very_high')}</th>
          <th>${this.$t('label.very_very_low')}</th>
          <th>${this.$t('road_network.total_km')}</th>
        </tr>`

        let tableRows = ''
        this.report.forEach(item => {
          tableRows += `<tr><td>${item.survey_year}</td>`
          if (!this.selectedRegion) tableRows += `<td>${item.region_description}</td>`
          if (!this.selectedDeu) tableRows += `<td>${this.$t('road_network.dep') + '-' + item.deu_description}</td>`
          tableRows += `<td class='numCell'>${numFormat(item.length_km_above_24000, 3)}</td>
            <td class='numCell'>${numFormat(item.length_km_between_12001_and_24000, 3)}</td>
            <td class='numCell'>${numFormat(item.length_km_between_7001_and_12000, 3)}</td>
            <td class='numCell'>${numFormat(item.length_km_between_3001_and_7000, 3)}</td>
            <td class='numCell'>${numFormat(item.length_km_between_1001_and_3000, 3)}</td>
            <td class='numCell'>${numFormat(item.length_km_between_251_and_1000, 3)}</td>
            <td class='numCell'>${numFormat(item.length_km_below_250, 3)}</td>
            <td class='numCell'>${numFormat(item.total_km, 3)}</td>
          </tr>`
        })

        const PrintWin = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
        PrintWin.document.write(`<!DOCTYPE html>
        <html>
          <head>
            <style>
              body { font-family: Arial, sans-serif; }
              table { width: 100%; border-collapse: collapse; margin: 20px 0; font-size: 12px; }
              th, td { border: 1px solid black; padding: 8px; }
              .numCell { text-align: right; }
            </style>
          </head>
          <body>${prtHtml}
                  <table>
                    <thead>${tableHeaders}</thead>
                    <tbody>${tableRows}</tbody>
            </table>
          </body>
        </html>`
        )

        //setTimeout(() => { // Timeout is needed to finish loading
        PrintWin.document.close();
        this.showSpinner = false
        PrintWin.focus();
        PrintWin.print();
        PrintWin.close();
        //}, 500);
      },
      onRegionChange(region_id, regionDescr) {
        //      if (region_id) this.selectedDeu = 0
        this.regionDescr = regionDescr
      },
      onDeuChange(deu_id, deuDescr) {
        this.deuDescr = deuDescr
      },
    },

    computed: {
      report() {
        return this.customSort(this.$store.state.Reports.report_4_data, 'deu_description')
      },
      tableData() {
        let result = !this.selectedRegion ? this.report : this.report.filter((item) => {
          return item.region_id == this.selectedRegion
        })
        if (this.selectedDeu) {
          result = result.filter((item) => { return item.deu_id == this.selectedDeu })
        }
        return result
      },
    },

    watch: {
      startYear(value) {
        if (!value) this.startYear = new Date().getFullYear() - 5
        this.reloadData()
      },
      endYear(value) {
        if (!value) this.endYear = new Date().getFullYear()
        this.reloadData()
      },
    }
  }
</script>
<style lang="scss" scoped>
.md-card {
  margin: 0px 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

::v-deep .md-table-head-label {
  font-size: 14px !important;
  text-align: center !important;
  overflow: visible !important;
  white-space: normal !important;
  padding-right: unset !important;
}
.md-table-cell.md-numeric {
  padding: 0px !important;
  padding-right: 18px !important;
}

.md-progress-spinner {
  margin: 18px;
  position: absolute;
  top: 25%;
  left: 45%;
  z-index: 20;
}
</style>